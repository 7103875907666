import {
  createContext,
  Dispatch,
  ReactNode,
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { SearchTableResponseModel } from '../../composites/searchDocument/ISearchDocument'
import { AuthContext } from '../AuthContext'
import {
  AccountDetailsInterface,
  AccountSearchByNumberPayloadInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  MappingInterface,
  UserPayloadInterface,
  UserRoleMapping,
  VisibilityDataResponseInterface,
} from './ISearchContext'
import {
  accountSearchByNumberAPI,
  clientSearchByNameAPI,
  getDropdownSearchAPI,
  getUserAccess,
  getUserEntitlement,
} from './SearchAPI'
import { getRepAccessInfoByUserName } from '../userVisibilityContext/UserVisibilityApi'

export interface SearchContextInterface {
  apiError: object | null | string
  dropdownList: MappingInterface
  accountDataList: SearchTableResponseModel[]
  accounts: AccountDetailsInterface[] | null
  setAccountDataList: Dispatch<React.SetStateAction<SearchTableResponseModel[]>>
  entityDataList: SearchTableResponseModel[]
  setEntityDataList: Dispatch<React.SetStateAction<SearchTableResponseModel[]>>
  userMappingList: UserRoleMapping
  userLoading: boolean
  searchClients: ClientSearchFn
  clients: ClientSearchByNameResponseInterface[] | null
  selectClient: SelectClientFn
  errorText: string
  setClients: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface[] | null>
  >
  selectedAccount: AccountDetailsInterface | null
  setAccounts: Dispatch<React.SetStateAction<AccountDetailsInterface[] | null>>
  selectAccount: SelectAccountFn
  selectedClient: ClientSearchByNameResponseInterface | null
  searchAccountsByNumber: AccountSearchByNumberFn
  visibilityData: any
  setVisibilityData: any
  selectedTab: string
  setSelectedTab: Dispatch<React.SetStateAction<string>>
  visibilityLoading: boolean
}
interface AccountSearchByNumberFn {
  (payload: AccountSearchByNumberPayloadInterface): Promise<boolean | void>
}
interface SelectAccountFn {
  (account: AccountDetailsInterface | null): void
}
interface SelectClientFn {
  (client: ClientSearchByNameResponseInterface | null): void
}

interface ClientSearchFn {
  (payload: ClientSearchByNamePayloadInterface): Promise<boolean | void>
}
const SearchContext = createContext<SearchContextInterface>(
  {} as SearchContextInterface
)

interface ContextProps {
  children?: ReactNode
}

const defaultUserMapPayload = {
  userId: '',
  userType: '',
  userEntitlement: [],
  entityCode: [],
  repCode: [],
  officeCode: [],
  accountnumbers: [],
}

export const SearchContextProvider = ({ children }: ContextProps) => {
  const authCtx = useContext(AuthContext)
  const [selectedTab, setSelectedTab] = useState<string>('Statements')
  const [apiError, setApiError] = useState<object | null | string>(null)
  const [dropdownList, setDropdownList] = useState<MappingInterface>({
    domainList: [],
  })
  const [accountDataList, setAccountDataList] = useState<
    SearchTableResponseModel[]
  >([])
  const [entityDataList, setEntityDataList] = useState<
    SearchTableResponseModel[]
  >([])
  const [userMappingList, setUserMappingList] = useState<UserRoleMapping>(
    defaultUserMapPayload
  )
  const [userLoading, setUserLoading] = useState(false)
  const [clients, setClients] = useState<
    ClientSearchByNameResponseInterface[] | null
  >(null)

  const [selectedClient, selectClient] =
    useState<ClientSearchByNameResponseInterface | null>(null)

  const [selectedAccount, selectAccount] =
    useState<AccountDetailsInterface | null>(null)
  const [accounts, setAccounts] = useState<AccountDetailsInterface[] | null>(
    null
  )
  const [errorText, setErrorText] = useState('')
  const [visibilityData, setVisibilityData] = useState(
    {} as VisibilityDataResponseInterface
  )
  const [visibilityLoading, setVisibilityLoading] = useState(false)
  const searchClients = useCallback(
    async (payload: ClientSearchByNamePayloadInterface) => {
      try {
        let flag = false
        await clientSearchByNameAPI(payload)
          .then((response) => {
            if (response.data.length === 0) {
              flag = true
              setErrorText('No Results Found')
            }
            setClients(response.data)
          })
          .catch(() => {
            flag = false
            setErrorText('Please try again')
          })
        return flag
      } catch (error) {
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    },
    []
  )

  const searchAccountsByNumber = useCallback(
    async (payload: AccountSearchByNumberPayloadInterface) => {
      try {
        // setApiLoading(true)
        let flag = false
        await accountSearchByNumberAPI(payload)
          .then((response) => {
            if (
              !response.data.length ||
              !response.data[0].detailsByAccounts.length
            ) {
              flag = true
              setErrorText('No Results Found')
            }
            // setClients(response.data)
            // selectClient(response.data[0])
            setAccounts(response.data[0].detailsByAccounts)
          })
          .catch(() => {
            flag = false
            setErrorText('Please try again')
          })
        // setApiLoading(false)
        return flag
      } catch (error) {
        // setApiLoading(false)
        if (error instanceof Error) {
          setApiError(error)
          selectClient({} as ClientSearchByNameResponseInterface)
          setAccounts([])
        }
      }
    },
    []
  )

  useEffect(() => {
    async function getDropdownList() {
      try {
        const response = await getDropdownSearchAPI()
        setDropdownList(response.data)
      } catch (error) {
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    }

    async function getUserAccessList(payload: UserPayloadInterface) {
      setUserLoading(true)
      try {
        const response = await getUserAccess(payload)
        userMappingList.accountnumbers = response.data.accountnumbers
        userMappingList.entityCode = response.data.entityCode
        userMappingList.officeCode = response.data.officeCode
        userMappingList.repCode = response.data.repCode
        setUserMappingList({ ...userMappingList })
        setUserLoading(false)
      } catch (error) {
        setUserLoading(false)
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    }
    async function getUserEntitlementList(payload: UserPayloadInterface) {
      setUserLoading(true)
      try {
        const response = await getUserEntitlement(payload)
        userMappingList.userEntitlement = response.data.userEntitlement
        userMappingList.userId = response.data.userId
        userMappingList.userType = response.data.userType
        setUserMappingList({ ...userMappingList })
        setUserLoading(false)
        if (authCtx?.userEmail) {
          getUserAccessList({ Role: authCtx?.userEmail })
        }
      } catch (error) {
        setUserLoading(false)
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    }
    if (authCtx?.accessToken) {
      getDropdownList()
      if (
        authCtx?.loginRoles?.Entitlements &&
        authCtx?.loginRoles?.Entitlements.length > 0
      )
        getUserEntitlementList({
          Role: authCtx?.loginRoles?.Entitlements[0],
        })
      // if (!authCtx?.loginRoles?.Entitlements.includes('DM-CORRESPONDENT')) {
      getRepAccessInfoByUserName()
        .then((res) => {
          setVisibilityData(res.data)
          setVisibilityLoading(true)
          setUserLoading(false)
        })
        .catch((err) => {
          console.log('error', err)
          setVisibilityLoading(true)
          setUserLoading(false)
        })
      // } else setUserLoading(false)
    }
  }, [authCtx?.accessToken])

  const value = useMemo(
    () => ({
      apiError,
      dropdownList,
      accountDataList,
      setAccountDataList,
      entityDataList,
      setEntityDataList,
      userMappingList,
      userLoading,
      searchClients,
      clients,
      selectClient,
      errorText,
      setClients,
      setAccounts,
      accounts,
      searchAccountsByNumber,
      selectAccount,
      selectedAccount,
      selectedClient,
      visibilityData,
      setVisibilityData,
      setSelectedTab,
      selectedTab,
      visibilityLoading,
    }),
    [
      apiError,
      dropdownList,
      accountDataList,
      entityDataList,
      userMappingList,
      userLoading,
      searchClients,
      clients,
      errorText,
      accounts,
      searchAccountsByNumber,
      selectedAccount,
      selectedClient,
      visibilityData,
      selectedTab,
      visibilityLoading,
    ]
  )

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export default SearchContext
